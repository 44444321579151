import React from "react";
import Skills from "../skills/Skills";

const resumeContent = [
  {
    jobPosition: `Full Stack Developer`,
    jobType: `Temco Logistics | Hybrid`,
    jobDuration: `Mar 2022 - Ongoing`,
    timeDuraton: `Full Time`,
    compnayName: "Temco Logistics",
    jobDescription: `Created from scratch full-stack production applications that are able to process thousands of business transactions a day.\nTranslation of complex business requirements into development solutions such as SQLServer tables and stored procedures.\nDeveloped in-house applications to streamline testing in C# and JavaScript environments.\nImplemented multiple APIs that feature authorization, authentication, and password hashing.\nUtilized architectural design patterns such as the Controller-Service-Repository as well as SPA framework technologies`,
  },
  {
    jobPosition: `Software Engineer Intern`,
    jobType: `HCL Technologies | Remote`,
    jobDuration: `Sep 2021 - Jan 2022`,
    timeDuraton: `Part Time`,
    compnayName: "HCL Technologies",
    jobDescription: `Scrum-based development environment,
    created a Micro-service-styled Java project deployed through AWS cloud services
    using Docker, Kubernetes, and Spring.`,
    delayAnimation: "100",
  },
  {
    jobPosition: `Operations Administrator`,
    jobType: `FedEx Ground | On-Site`,
    jobDuration: `Jun 2020 - Sep 2021`,
    timeDuraton: `Part Time`,
    compnayName: "FedEx Ground",
    jobDescription: `Managed time and work-flow independently, 
    actively mitigated damage/loss of customer packages, 
    and handled/processed hazardous materials.`,
    delayAnimation: "200",
  },
  {
    jobPosition: `Package Handler`,
    jobType: `FedEx Ground | On-Site`,
    jobDuration: `Jul 2018 - Jun 2020`,
    timeDuraton: `Part Time`,
    compnayName: "FedEx Ground",
    jobDescription: `Scanned and loaded packages in an intensive, fast-paced environment
    while adhering to FedEx's safety & conduct policy.`,
    delayAnimation: "300",
  },
];

const educatonContent = [
  {
    passingYear: "2022",
    degreeTitle: "Bachelors in Computer Science",
    instituteName: "California State University - Fullerton",
  },
  {
    passingYear: "2019",
    degreeTitle: "Associates in Computer Science",
    instituteName: "Fullerton College",
  }
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div
                className="resume-row"
                key={i}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay={val.delayAnimation}
              >
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h6>{val.jobPosition}</h6>
                      <label>{val.jobType}</label>
                      <p>{val.jobDuration}</p>
                      <div className="rb-time">{val.timeDuraton}</div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.compnayName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Education & Skills</h3>{" "}
          </div>

          <div className="row align-items-center">
            <div
              className="col-lg-4 m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <span>{val.passingYear}</span>
                    <h6>{val.degreeTitle} </h6>
                    <p>{val.instituteName}</p>{" "}
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div
              className="col-lg-7 ml-auto m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <Skills />
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
