import React from "react";
import Social from "../Social";
import Testimonials from "../testimonial/TestimonialAnimation";
import Services from "../service/ServiceAnimation";
import Awards from "../award/AwardsAnimation";

const About = () => {
  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/me.jpg" alt="my-face" />
                  </div>

                  <Social />

                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  <p>Software Engineer</p>
                  <h3>Evan Delasota</h3>
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div
              className="col-lg-7 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="about-info">
                <div className="title">
                  <h3>Biography</h3>
                </div>
                <div className="about-text">
                  <p>
                    Hey, I'm Evan - a full-stack developer and computer science graduate from CSU - Fullerton. I have experience in the development life-cycle of full-stack applications from start to finish. As of now i'm currently studying back end related technologies, and will be
                    obtaining cloud certifications throughout the year. I'm always looking for opportunities to further my career in software
                    development, so don't be afraid to message me!
                  </p>
                  <p>
                    In my free time I enjoy bodybuilding, muay thai, sports such as snowboarding, traveling to different places, and 
                    immersing myself in a solid video game. I also enjoy helping people out and this career path has definitely given
                    me the opportunity to do just that.
                  </p>
                </div>
                <div className="info-list">
                  <div className="row">
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>Name: </label>
                          <span>Evan Delasota</span>
                        </li>
                        <li>
                          <label>Age: </label>
                          <span>27</span>
                        </li>
                        <li>
                          <label>Current Game: </label>
                          <span>Elden Ring</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>Email: </label>
                          <span>evan.delasota@gmail.com</span>
                        </li>
                        <li>
                          <label>Current Location: </label>
                          <span>Los Angeles, CA</span>
                        </li>
                        <li>
                          <label>Hobbies: </label>
                          <span>Coding, Traveling, Fashion, Fitness, Dancing, Muay Thai, Video Games, Snowboarding</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End col */}
          </div>

          {/* separated */}

          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/img/border-dark.png"
              })`,
            }}
          ></div>

          {/* End separated */}
          <div className="title">
            <h3>What I do</h3>
          </div>

          <Services />

          {/* End .row */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Certifications</h3>
          </div>

          <Awards />
          {/* End Awards */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          {/* <div className="title">
            <h3>Testimonials.</h3>
          </div>

          <Testimonials />
          End Testimonaial */}
        </div>
      </section>
    </>
  );
};

export default About;
