import React from "react";
import {
  FaFacebookF,
  FaSpotify,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaPinterestP,
  FaCertificate,
  FaGithub,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaGithub />, link: "https://www.github.com/evan-delasota" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/in/evan-delasota" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/evan_delasota" },
  { Social: <FaSpotify />, link: "https://open.spotify.com/user/09evan09" },
  { Social: <FaCertificate />, link: "https://www.credly.com/users/evan-delasota.0eaf7e51" },
];

const Social = () => {
  return (
    <div className="nav social-icons justify-content-center">
      {SocialShare.map((val, i) => (
        <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
          {val.Social}
        </a>
      ))}
    </div>
  );
};

export default Social;
